/*
 * @Description:
 * @Author: YJJ
 * @Date: 2023-12-12 19:31:24
 * @LastEditTime: 2024-01-25 10:12:37
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    // component: () => import("../views/registration.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/registration.vue"),
  },
  {
    path: "/result",
    name: "result",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/payResult.vue"),
  },
  {
    path: "/seachResult",
    name: "seachResult",
    component: () => import("../views/seachResult.vue"),
  },
  {
    path: "/declaration",
    name: "declaration",
    component: () => import("../views/declaration.vue"),
  },
  {
    path: "/information",
    name: "information",
    component: () => import("../views/information.vue"),
  },
  {
    path: "/serch",
    name: "serch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: HomeView,
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/feedback.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

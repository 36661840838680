<!--
 * @Description: 
 * @Author: YJJ
 * @Date: 2023-12-08 17:14:52
 * @LastEditTime: 2024-01-23 20:18:06
-->
<template>
  <div class="content">
    <div class="title"><img src="../assets/hometop.png" alt="" /></div>
    <div class="infoimg"><img src="../assets/homeInfo.png" alt="" /></div>

    <div class="operateList">
      <template v-for="(item, i) in operateList">
        <div @click="jupmReg(item.path, item.text)" :key="i" class="operateListItem">
          <img :src="item.img" alt="" /><span>{{ item.text }}</span>
        </div>
      </template>
    </div>
    <div class="stepList">
      <img src="../assets/stepInfo.png" alt="" />
    </div>
    <div class="prompt">
      <p>*我公司只收取企业管理咨询服务费*</p>
      <p>*不代替和代理政府管理部门收取任何费用*</p>
      <p></p>
      <p style="color:#000000">中观信息科技(陕西)有限公司</p>
      <p style="color:#000000"><a style="color: #000;" href="http://beian.miit.gov.cn/" target="_blank">陕ICP备2023018356号</a></p>
    </div>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="80%">
      <div class="dialogTitle">查询进度</div>
      <div class="seach">
        <el-input v-model="enterpriseName" placeholder="请输入企业名称查询"></el-input>
        <el-button type="primary" @click="seachTo">立即查询</el-button>
      </div>
      <div class="seach">
        <el-input v-model="phone" placeholder="请输入手机号码查询"></el-input>
        <el-button type="primary" @click="seachTo">立即查询</el-button>
      </div>
      <div class="close" @click="dialogVisible = false"><img src="../assets/close.png" alt="" /></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      operateList: [
        {
          img: require("@/assets/vip1.png"),
          text: "初级会员",
          path: "/registration",
        },
        {
          img: require("@/assets/vip2.png"),
          text: "中级会员",
          path: "",
        },
        {
          img: require("@/assets/svip.png"),
          text: "高级会员",
          path: "",
        },
        {
          img: require("@/assets/icon-3.png"),
          text: "证照代办",
          path: "",
        },
        {
          img: require("@/assets/icon-2.png"),
          text: "作废声明",
          path: "/declaration",
        },
        {
          img: require("@/assets/icon-6.png"),
          text: "查询进度",
          path: "",
        },
      ],
      carList: [
        {
          carNum: 1,
          carTitle: "您找到我们",
          carTextOne: "帮您预约年报代办",
          carTextTwo: "出具快速申请协议",
        },
        {
          carNum: 2,
          carTitle: "签定正规合同",
          carTextOne: "与您签订电子合同",
          carTextTwo: "保证服务超出标准",
        },
        {
          carNum: 3,
          carTitle: "提供代办服务",
          carTextOne: "马上办理年报预约",
          carTextTwo: "确保按时完成约定",
        },
        {
          carNum: 4,
          carTitle: "递交部门审查",
          carTextOne: "实时跟进服务状态",
          carTextTwo: "预约进展及时通知",
        },
      ],
      phone: "",
      enterpriseName: "",
      dialogVisible: false,
    };
  },
  components: {},
  methods: {
    jupmReg(path, text) {
      if (text == "查询进度") {
        this.dialogVisible = true;
      } else {
         if(path)this.$router.push(path);
         else this.$message.info("正在建设中，敬请期待!");
      }
    },
    seachTo() {
      if(this.enterpriseName || this.phone){
        if(this.phone && /^1[3456789]\d{9}$/.test(this.phone) == false){
          this.$message.error("请输入正确的手机号码!");
        }else{
          this.$router.push({name:"seachResult",params:{subjectName:this.enterpriseName,phone:this.phone}});
        }
     
      }else{
          this.$message.error("请输入查询信息!");
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
  // margin-top: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    width: 100%;
    height: 164px;
    img {
      width: 100%;
      overflow: auto;
    }
  }
  .infoimg {
    width: 100%;
    height: 164px;
    margin-top: -25px;
    img {
      width: 100%;
      overflow: auto;
    }
  }
  .operateList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 40px;
    width: calc(100% - 30px);
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px #d7d7e8;
    border-radius: 10px;
    .operateListItem {
      margin: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 80px;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 6px;
      }
      span {
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #1a1b1c;
        line-height: 14px;
      }
    }
  }
  .stepList {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 20px;
    img {
      width: 100%;
      height: 293px;
    }
  }
  .prompt {
    margin-top: 20px;
    padding-bottom: 20px;
    p {
      text-align: center;
      color: red;
      margin-bottom: 15px;
      height: 10px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #f75452;
      line-height: 10px;
    }
  }
  .el-dialog__wrapper {
    ::v-deep .el-dialog {
      border-radius: 10px !important;
      padding: 15px;
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 0;
        padding-top: 40px;
        .dialogTitle {
          text-align: center;
          margin-top: -30px;
          height: 14px;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #1a1b1c;
          line-height: 14px;
          margin-bottom: 30px;
        }
        .seach {
          margin-bottom: 20px;
          &:first-child {
            margin-bottom: 30px;
          }
          .el-input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            .el-input__inner {
              border: 0;
              background: #f6f6f7;
            }
          }
          .el-button {
            width: 100% !important;
            margin-top: 15px;
            border-radius: 22px;
            background: #2865f3;
          }
        }
        .close {
          position: absolute;
          bottom: -60px;
          left: 45%;
          width: 30px;
          height: 30px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

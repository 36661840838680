/*
 * @Description:
 * @Author: YJJ
 * @Date: 2023-12-08 17:14:52
 * @LastEditTime: 2023-12-09 19:17:07
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { post, get } from "./utils/http";
//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
